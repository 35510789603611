import { useState, useCallback } from 'react';

import { Backend } from '../../../../api/endpoints';
import { fetchPaymentStatus } from './payment-status-api';

export function usePaymentStatus(paymentNumber: number) {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<Backend.PaymentStatus | null>(null);

  const fetchStatus = useCallback(async () => {
    try {
      setLoading(true);
      const { status } = await fetchPaymentStatus(paymentNumber);
      setStatus(status);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [paymentNumber]);

  // useEffect(() => {
  //   fetchStatus();
  // }, [fetchStatus]);

  return { status, loading, fetchStatus };
}
