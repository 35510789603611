import { Backend } from '../../api/endpoints';
import { CardStylesProps } from './types';
import isic from './assets/isic.png';
import itic from './assets/itic.png';
import alive from './assets/alive.png';
import stud from './assets/student.png';
import ext from './assets/externi_uzivatel.png';
import empl from './assets/zamestnanec.png';
import alum from './assets/alumni.png';

type CardInfo = { cardPath: string | null; cardStyles?: CardStylesProps };

export const getCardStyles = (
  cardType?: Backend.CardType,
  licenceType?: Backend.LicenceType
): CardInfo => {
  if (licenceType === 'ISIC') {
    return {
      cardPath: isic,
    };
  }
  switch (cardType) {
    case 'ITIC':
      return {
        cardPath: itic,
        cardStyles: {
          fontSize: 10,
          name: {
            left: 10,
            bottom: 54,
          },
          validTo: {
            left: 81,
            bottom: 38,
          },
          birthDate: {
            left: 10,
            bottom: 38,
          },
        },
      };
    case 'ALIVE':
      return {
        cardPath: alive,
        cardStyles: {
          fontSize: 9,
          image: {
            top: 15,
            right: 10,
          },
          name: {
            left: 14,
            bottom: 56,
          },
          validTo: {
            left: 87,
            bottom: 40,
          },
          birthDate: {
            left: 14,
            bottom: 40,
          },
          personNumber: {
            display: 'none',
          },
        },
      };
    case 'ALUM':
    case 'CZV':
      return {
        cardPath: alum,
        cardStyles: {
          image: {
            top: 27,
            right: 8,
          },
          name: {
            bottom: 45,
            left: 11,
          },
          validTo: {
            display: 'none',
          },
          birthDate: {
            bottom: 71,
            left: 11,
          },
          personNumber: {
            display: 'none',
          },
          barcode: {
            display: 'block',
          },
        },
      };
    case 'EUSA':
    case 'EUSP':
    case 'EXT':
      return {
        cardPath: ext,
        cardStyles: {
          name: {
            bottom: 45,
            left: 11,
          },
          validTo: {
            display: 'none',
          },
          birthDate: {
            bottom: 71,
            left: 11,
          },
          personNumber: {
            display: 'none',
          },
        },
      };
    case 'STUD':
    case 'STAZ':
      return {
        cardPath: stud,
        cardStyles: {
          image: {
            top: 22,
            right: 13,
          },
          name: {
            bottom: 58,
            left: 7,
          },
          validTo: {
            bottom: 7,
          },
          birthDate: {
            bottom: 31,
          },
          personNumber: {
            bottom: 31,
            left: 98,
          },
        },
      };
    case 'ZAME':
      return {
        cardPath: empl,
        cardStyles: {
          name: {
            bottom: 45,
            left: 11,
          },
          image: {
            top: 28,
            right: 9,
          },
          validTo: {
            display: 'none',
          },
          birthDate: {
            left: 11,
            bottom: 70,
          },
          personNumber: {
            display: 'none',
          },
          barcode: {
            display: 'block',
          },
        },
      };
    case 'KLIC':
      return {
        cardPath: null,
      };
  }

  return {
    cardPath: isic,
  };
};
