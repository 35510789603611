import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { TableDetailComponent, TableExpandedComponent } from './table-types';

interface RowProps<T> {
  rowData: T;
  children: React.ReactNode;
  expandable: boolean;
  ExpandedComponent?: TableExpandedComponent<T>;
  DetailComponent?: TableDetailComponent<T>;
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  box: {
    padding: '1rem 0',
  },
});

export function EnhancedTableRow<T>({
  rowData,
  children,
  expandable,
  ExpandedComponent,
  DetailComponent,
}: RowProps<T>) {
  const classes = useRowStyles();
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <TableRow hover className={expandable ? classes.root : undefined}>
        {expanded && ExpandedComponent ? (
          <ExpandedComponent row={rowData} />
        ) : (
          children
        )}
        {expandable && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>

      {expandable && (
        <TableRow>
          <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} colSpan={12}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Box className={classes.box}>
                {DetailComponent && <DetailComponent data={rowData} />}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
