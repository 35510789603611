import React from 'react';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

import { Table } from '../../../components/table/table';
import { TableColumn } from '../../../components/table/table-types';
import { useMyFiles } from './my-files-hook';
import { Backend } from '../../../api/endpoints';

const fileColumns: TableColumn<Backend.BillDetailDto>[] = [
  {
    id: 'name',
    label: 'Typ',
  },
  {
    id: 'created',
    label: 'Datum',
    valueSelector: ({ row }) => new Date(row.created).toLocaleString(),
  },
  {
    id: 'fileId',
    label: '',
    align: 'right',
    CellComponent: function DownloadButton({ data }) {
      return (
        <Button
          color="primary"
          variant="outlined"
          startIcon={<GetAppIcon />}
          href={`/api/prukazy/files/${data}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Stáhnout PDF
        </Button>
      );
    },
  },
];

export function MyFiles() {
  const { data, loading } = useMyFiles();

  return (
    <Table<Backend.BillDetailDto>
      title="Moje soubory"
      columns={fileColumns}
      rows={data}
      count={data.length}
      loading={loading}
    />
  );
}
