import React, { useContext, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { Form } from '@eas/common-web';
import { useHistory } from 'react-router-dom';

import { NewCardPhoto } from './new-card-photo';
import { NewCardInfo } from './new-card-info';
import { NewCardConfirmation } from './new-card-confirmation';
import { NewCardValues } from './new-card-types';
import { useNewCard } from './hooks/new-card-hook';
import { newCardValidationSchema } from './new-card-validation';
import { useCardRights } from './hooks/card-right-hook';
import { Page } from '../../../components/page/page';
import { UserInfoContext } from '../../../components/user-info-context/user-info-context';

export function NewCard() {
  const { goBack } = useHistory();
  const { userInfo } = useContext(UserInfoContext);
  const { cardsAndLicences, loading } = useCardRights();
  const { ref, submitting, handleSubmit, validate } = useNewCard(
    cardsAndLicences
  );

  const initialValues = useMemo<NewCardValues>(
    () => ({
      photo: null,
      cardId: '',
      pickupWorkplace: '',
      gdpr: userInfo?.escApproval ?? false,
      email: '',
    }),
    [userInfo?.escApproval]
  );

  const noRights = !cardsAndLicences.length;
  const disabled = noRights || submitting;

  return (
    <Form
      ref={ref}
      initialValues={initialValues}
      editing
      onSubmit={handleSubmit}
      validationSchema={newCardValidationSchema}
    >
      <Grid item xs={12}>
        <Page>
          <Page.Section>
            <Page.Header title="Nový průkaz" onBackButton={() => goBack()} />
            <NewCardPhoto
              validate={validate}
              cardsAndLicences={cardsAndLicences}
              disabled={disabled}
            />
          </Page.Section>
          <Divider />
          <Page.Section>
            <NewCardInfo
              validate={validate}
              cardsData={{ cardsAndLicences, loading, noRights }}
              disabled={disabled}
            />
          </Page.Section>
          <Divider />
          {}
          <Page.Section>
            <NewCardConfirmation validate={validate} disabled={disabled} />
          </Page.Section>
        </Page>
      </Grid>
    </Form>
  );
}
