import { Backend } from 'api/endpoints';

export const fetchBlockCard = async (id: number) =>
  await fetch(`/api/prukazy/auth/card/${id}/block`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });

export const fetchUnblockCard = async (id: number) =>
  await fetch(`/api/prukazy/auth/card/${id}/unblock`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });

export const fetchLicencePaymentLink = async (
  body: Backend.PurchaseStickerDto
): Promise<Backend.PaymentInfoDto> => {
  const res = await fetch('/api/prukazy/auth/card/purchase-sticker', {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  });

  if (!res.ok) {
    throw new Error(await res.json());
  }

  return await res.json();
};
