import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '2rem',
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
  },
}));

interface Props {
  onBackButton?: () => void;
  title: string;
}

export const PageHeader = ({ title, onBackButton }: Props) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" spacing={2} className={classes.root}>
      {onBackButton && (
        <Grid item>
          <IconButton type="button" onClick={onBackButton}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
      )}
      <Grid item>
        <Typography component="span" className={classes.title}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};
