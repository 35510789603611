import React, { ReactNode, useContext } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { DialogContext } from '../dialog-context';

interface Props {
  title: string;
  actions?: ReactNode;
  children: ReactNode;
  closeBtnLabel?: string;
  beforeClose?: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 300,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    height: 48,
    backgroundColor: '#F0F3F4',
  },
  content: {},
  actions: {},
}));

export function DefaultDialog({
  title,
  actions,
  children,
  closeBtnLabel = 'Zavřít',
  beforeClose,
}: Props) {
  const { close } = useContext(DialogContext);
  const classes = useStyles();

  const handleClose = () => {
    beforeClose?.();
    close();
  };

  return (
    <div className={classes.root}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent className={classes.content}>{children}</DialogContent>
      <DialogActions className={classes.actions}>
        {actions}
        <Button onClick={handleClose}>{closeBtnLabel}</Button>
      </DialogActions>
    </div>
  );
}
