import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';

import { DialogProps } from './types';

export function Dialog({
  opened,
  size,
  Content,
  onClose,
  initialValues,
  disableBackdropClick = false,
}: DialogProps & { onClose: () => void }) {
  return (
    <MuiDialog
      maxWidth={size}
      open={opened}
      onClose={onClose}
      disableBackdropClick={disableBackdropClick}
    >
      <Content initialValues={initialValues} onClose={onClose} />
    </MuiDialog>
  );
}
