import React from 'react';
import { Link } from 'react-router-dom';
import { FormSubmitButton } from '@eas/common-web';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import colors from '../../../constants/colors';
import { FormFieldCheckbox } from '../../../components/form/checkbox/checkbox';
import { useFormErrors } from '../../../hooks/use-form-errors';
import { NewCardValues } from './new-card-types';

const useStyles = makeStyles(() => ({
  button: {
    color: 'white',
    backgroundColor: colors.blue,
    '&:hover': {
      backgroundColor: colors.blue,
    },
  },
}));

type Props = {
  validate: () => Promise<void>;
  disabled?: boolean;
};

export function NewCardConfirmation({ validate, disabled }: Props) {
  const classes = useStyles();
  const { getFieldError } = useFormErrors<NewCardValues>();

  return (
    <Grid container spacing={2}>
      <Grid item>
        <FormControlLabel
          control={<FormFieldCheckbox name="gdpr" validate={validate} />}
          onBlur={validate}
          disabled={disabled}
          label={
            <>
              Souhlasím s&nbsp;
              <a
                href="https://cuni.cz/UK-5360-version1-informace_ke_zpracovani_osobnich_udaju.pdf"
                target="_blank"
                rel="noreferrer"
                title="Poučení.pdf"
              >
                podmínkami zpracování mých osobních údajů
              </a>
            </>
          }
        />
        <FormHelperText error>{getFieldError('gdpr')}</FormHelperText>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item>
          <FormSubmitButton
            variant="contained"
            type="submit"
            className={classes.button}
            disabled={disabled}
          >
            Objednat průkaz
          </FormSubmitButton>
        </Grid>
        <Grid item>
          <Button component={Link} to="/portal/prukazy">
            Zrušit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
