import React from 'react';

import { NavItem } from './nav-item';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import { rootNavItems } from './nav-items';

const useStyles = makeStyles((theme) => ({
  nav: {
    width: '100%',
  },
  navItem: {
    margin: '0.5rem 0',
    textDecorationColor: 'transparent',
    transition: '0.3s',
    '& svg': {
      color: '#ffffff',
    },
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: '#ffffff',
    },
  },
  activeLink: {
    // paddingLeft: 8,
    backgroundColor: '#fff',
    textDecorationColor: 'transparent',
    transition: '0.3s',
    '& span': {
      color: theme.palette.secondary.main,
    },
    '& svg': {
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      backgroundColor: '#fff',
      textDecoration: 'underline',
      textDecorationColor: theme.palette.secondary.main,
    },
  },
}));

export function SidePanelNav() {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.nav}>
      {rootNavItems.map(({ label, linkTo, Icon, subItems = [] }) => (
        <NavItem
          key={label}
          label={label}
          Icon={Icon}
          linkTo={linkTo}
          className={classes.navItem}
          activeClassName={classes.activeLink}
          subItems={subItems}
        />
      ))}
    </List>
  );
}
