import { Backend } from '../../api/endpoints';

export const fetchUserInfo = async (): Promise<
  Backend.PersonInformationDto
> => {
  const res = await fetch('/api/prukazy/auth/uk/persons/logged-in', {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });

  return await res.json();
};
