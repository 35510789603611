import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    backgroundColor: '#fafafa',
  },
}));

export function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <div>
        <a
          href="https://cuni.cz/UK-5360-version1-obchodni_podminky_karty.pdf"
          title="Všeobecné obchodní podmínky"
          target="_blank"
          rel="noreferrer"
        >
          Všeobecné obchodní podmínky
        </a>
      </div>

      <div>
        Kontakt:&nbsp;
        <a href="mailto:prukazyUK@cuni.cz" title="Kontakt">
          prukazyUK@cuni.cz
        </a>
      </div>
      <div>
        Výdejní centra:&nbsp;
        <a href="https://cuni.cz/UK-3249.html" title="Výdejní centra">
          https://cuni.cz/UK-3249.html
        </a>
      </div>
      <div>
        Web:&nbsp;
        <a href="https://prukazy.cuni.cz" title="Web">
          https://prukazy.cuni.cz
        </a>
      </div>
    </footer>
  );
}
