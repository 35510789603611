import React from 'react';

import { Card } from '../../../models';

interface CardTypeOfHolderCellProps {
  data: Card['typeOfHolder'];
  allData: Card;
}

export const typeOfHolderMapping: Record<Card['typeOfHolder'], string> = {
  C: 'Výdejní centrum',
  O: 'Osoba',
  ORG: 'Organizační složka',
  Z: 'Ztraceno',
};

export const CardTypeOfHolderCell = ({ data }: CardTypeOfHolderCellProps) => (
  <span>{typeOfHolderMapping[data]}</span>
);
