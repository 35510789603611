import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../constants/colors';
import { Card } from '../../../../models';

interface CardDetailsItemProps {
  label: string;
  data: Card;
  render: (data: Card) => JSX.Element | string;
}

const useStyles = makeStyles(() => ({
  label: {
    color: colors.gray,
  },
}));

export function CardDetailsItem({ label, data, render }: CardDetailsItemProps) {
  const classes = useStyles();

  return (
    <Grid item xs={6} sm={4}>
      <Grid container direction="column" spacing={1}>
        <Grid item className={classes.label}>
          {label}
        </Grid>
        <Grid item>{render(data)}</Grid>
      </Grid>
    </Grid>
  );
}
