import React from 'react';

import { formFieldFactory } from '@eas/common-web';
import MuiRadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup';
import { EmptyWrapper } from '../empty-wrapper/empty-wrapper';

type Props = RadioGroupProps & {
  onChange: (value: string | null) => void;
};

export function RadioGroup({ onChange, children, ...otherProps }: Props) {
  return (
    <MuiRadioGroup
      onChange={(e) => onChange(e.target.value as string)}
      {...otherProps}
    >
      {children}
    </MuiRadioGroup>
  );
}

export const FormFieldRadioGroup = formFieldFactory(RadioGroup, EmptyWrapper);
