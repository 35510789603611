import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { UserContext } from '@eas/common-web';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Redirect } from 'react-router-dom';

import vpukLogo from '../assets/logo-uk-color.png';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 34,
    marginBottom: 25,
    maxWidth: 500,
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    maxWidth: 275,
    height: 'auto',
    marginBottom: 20,
  },
  loginButton: {
    marginTop: '1rem',
    width: 220,
    fontSize: 16,
    padding: '14px 0',
    textTransform: 'none',
  },
}));

export function Login() {
  const classes = useStyles();
  const { push } = useHistory();
  const { isLogedIn, reload } = useContext(UserContext);

  const isDevelopmentEnviroment = process.env.NODE_ENV === 'development';

  /* mocked login function for dev */
  const devLogin = async () => {
    await fetch('/api/prukazy/public/me', {
      method: 'GET',
      headers: new Headers({ cuniPersonalID: '59960618' }),
    });

    await reload();

    push('/portal/prukazy');
  };

  /* login function for production */
  const login = async () => {
    window.location.href = window.location.origin + '/secure';
  };

  if (isLogedIn()) {
    return <Redirect to="/portal/prukazy" />;
  }

  return (
    <div className={classes.page}>
      <div className={classes.loginContainer}>
        <img src={vpukLogo} className={classes.logo} />
        <Typography component="h1" className={classes.title} align="center">
          Portál studentských a zaměstnaneckých průkazů
        </Typography>
        <Button
          onClick={isDevelopmentEnviroment ? devLogin : login}
          className={classes.loginButton}
          color="primary"
          variant="contained"
          size="large"
        >
          Přihlásit se
        </Button>
      </div>
    </div>
  );
}
