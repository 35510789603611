import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../constants/colors';

interface Props {
  label: string;
  content?: string;
}

const useStyles = makeStyles(() => ({
  label: {
    color: colors.gray,
  },
}));

export const ProfileItem = ({ label, content }: Props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Grid container direction="column">
        <Grid item>
          <Typography className={classes.label}>{label}</Typography>
        </Grid>
        <Grid item>
          <Typography>{content || '-'}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
