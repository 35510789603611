import { useState, useContext, useEffect } from 'react';
import { SnackbarContext, SnackbarVariant } from '@eas/common-web';

import { fetchFiles } from './my-files-api';
import { Backend } from '../../../api/endpoints';

export const useMyFiles = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Backend.BillDetailDto[]>([]);
  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await fetchFiles();
        setData(
          res.items.sort(
            (a, b) =>
              new Date(b.created).getTime() - new Date(a.created).getTime()
          )
        );
      } catch (err) {
        showSnackbar('Načtení souborů selhalo', SnackbarVariant.ERROR);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [showSnackbar]);

  return { data, loading };
};
