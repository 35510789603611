import { Result } from '../../../models/result';
import { Backend } from '../../../api/endpoints';
import { CardOrderDto } from './new-card-types';

export const fetchLicenceRights = async (): Promise<
  Result<Backend.LicenceRight>
> => {
  const res = await fetch('/api/prukazy/auth/card/licence/right', {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });

  return await res.json();
};

export const fetchCardRights = async (): Promise<Result<Backend.CardRight>> => {
  const res = await fetch('/api/prukazy/auth/card/right', {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });

  return await res.json();
};

export const fetchPickupPlaces = async (): Promise<Backend.Workplace[]> => {
  const res = await fetch('/api/prukazy/auth/dictionary/workplace', {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });

  return await res.json();
};

export const fetchPaymentLink = async (
  body: Backend.PurchaseCardDto
): Promise<Backend.PaymentInfoDto> => {
  const res = await fetch('/api/prukazy/auth/card/purchase', {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  });

  if (!res.ok) {
    throw new Error(await res.json());
  }

  return await res.json();
};

export const fetchCardOrder = async (body: CardOrderDto): Promise<void> => {
  const res = await fetch('/api/prukazy/auth/card/card-without-license', {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  });

  if (!res.ok) {
    throw new Error(await res.json());
  }
};

export const uploadPhoto = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const res = await fetch('/api/prukazy/files', {
    method: 'POST',
    body: formData,
  });

  return await res.json();
};
