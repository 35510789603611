import { useEffect, useRef } from 'react';

export function useInterval(
  callback: () => void,
  delay: number | null,
  initialDelay?: number
) {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === null) {
      return;
    }

    let timeoutId: NodeJS.Timeout | undefined;
    let intervalId: NodeJS.Timeout | undefined;

    if (initialDelay) {
      timeoutId = setTimeout(() => {
        savedCallback.current();

        intervalId = setInterval(() => savedCallback.current(), delay);
      }, initialDelay);
    } else {
      intervalId = setInterval(() => savedCallback.current(), delay);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      if (intervalId) clearInterval(intervalId);
    };
  }, [delay, initialDelay]);
}
