import { Result, Card } from '../../models';
import { Backend } from 'api/endpoints';

export async function fetchAllCards(): Promise<Result<Card>> {
  const response = await fetch(`/api/prukazy/auth/card/search-all-cards`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
  const json = await response.json();

  return json;
}

export const fetchLicenceRights = async (): Promise<
  Result<Backend.LicenceRight>
> => {
  const res = await fetch('/api/prukazy/auth/card/licence/right', {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });

  return await res.json();
};
