import React, { FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  section: {
    padding: '2rem',
  },
}));

export const PageSection: FC = ({ children }) => {
  const classes = useStyles();

  return <section className={classes.section}>{children}</section>;
};
