import React, { useState } from 'react';
import MuiTable from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { v4 } from 'uuid';

import { TableCell } from './table-cell';
import {
  TableColumn,
  TableAction,
  TableDetailComponent,
  TableExpandedComponent,
} from './table-types';
import { EnhancedTableHead } from './table-head';
import { EnhancedTableRow } from './table-row';

interface TableProps<T> {
  title: string;
  rows: T[];
  count: number;
  loading: boolean;
  columns: TableColumn<T>[];
  expandableRows?: boolean;
  ExpandedComponent?: TableExpandedComponent<T>;
  actions?: TableAction[];
  DetailComponent?: TableDetailComponent<T>;
}

export function Table<T>({
  title,
  columns = [],
  rows = [],
  count,
  loading,
  expandableRows = false,
  ExpandedComponent,
  actions = [],
  DetailComponent,
}: TableProps<T>) {
  const [page, setPage] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Paper
      elevation={0}
      style={{
        border: '1px solid rgb(223,224,235)',
      }}
    >
      <TableContainer>
        <MuiTable
          aria-label="collapsible"
          aria-labelledby="tableTitle"
          size={'medium'}
          stickyHeader
        >
          <EnhancedTableHead<T>
            columns={columns}
            expandableRows={expandableRows}
            tableTitle={title}
            actions={actions}
            loading={loading}
          />

          <TableBody>
            {rows.length === 0 || loading ? (
              <TableRow>
                <MuiTableCell colSpan={12}>Žádné výsledky</MuiTableCell>
              </TableRow>
            ) : (
              rows.slice(page * 5, page * 5 + 5).map((r) => (
                <EnhancedTableRow
                  key={v4()}
                  rowData={r}
                  expandable={expandableRows}
                  ExpandedComponent={ExpandedComponent}
                  DetailComponent={DetailComponent}
                >
                  {columns.map((c) => (
                    <TableCell key={c.id} column={c} row={r} />
                  ))}
                </EnhancedTableRow>
              ))
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        rowsPerPage={5}
        page={page}
        rowsPerPageOptions={[]}
        onChangePage={handleChangePage}
      />
    </Paper>
  );
}
