import React, { useCallback, useContext, useState } from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  SnackbarContext,
  SnackbarVariant,
  UserContext,
  User,
} from '@eas/common-web';
import LinearProgress from '@material-ui/core/LinearProgress';

import { DefaultDialog } from '../../../../components/dialog/vpuk-dialog/vpuk-default-dialog';
import { DialogContentProps } from '../../../../components/dialog/types';
import { Card } from 'models';
import { DialogContext } from '../../../../components/dialog/dialog-context';
import { PaymentStatusDialogContent } from '../../new-card/payment-status/payment-status';
import { fetchLicencePaymentLink } from './actions-api';

interface Values {
  card?: Card;
}

export function RevalidateCardDialogContent({
  initialValues,
}: DialogContentProps<Values>) {
  const { showSnackbar } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const card = initialValues?.card;
  const { open } = useContext(DialogContext);
  const { user } = useContext<UserContext<User>>(UserContext);

  const handleLicenceOrder = useCallback(
    async (card?: Card) => {
      if (!card || !card.newLicenceData || !user) {
        showSnackbar(
          'Objednání selhalo, špatně načtené údaje',
          SnackbarVariant.ERROR
        );
        return;
      }

      try {
        setLoading(true);

        const body = {
          personId: card.newLicenceData.personId,
          cardId: card.id,
          cardType: card.cardType,
          licenceValidFrom: card.newLicenceData.licenceRightValidFrom,
          licenceValidTo: card.newLicenceData.licenceRightValidTo,
          licenceType: card.newLicenceData.licenceType,
          email: user.email.includes('@')
            ? user.email
            : `${user.email}@test.com`, // bypass BE validation for test user
        };

        const { paymentNumber, link } = await fetchLicencePaymentLink(body);
        window.open(link, '_blank');
        open({
          size: 'md',
          initialValues: {
            paymentLink: link,
            paymentNumber,
          },
          Content: PaymentStatusDialogContent,
          disableBackdropClick: true,
        });
      } catch (err) {
        showSnackbar('Vytvoření platby selhalo', SnackbarVariant.ERROR);
      } finally {
        setLoading(false);
      }
    },
    [user, showSnackbar, open]
  );

  return (
    <DefaultDialog
      title="Prodloužení licence"
      actions={
        <>
          <Button
            color="primary"
            onClick={() => handleLicenceOrder(card)}
            disabled={loading}
          >
            Potvrdit
          </Button>
        </>
      }
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <Typography>
          Skutečně si přejete prodloužit licenci na průkazu za{' '}
          {card?.newLicenceData?.price} CZK?
        </Typography>
      )}
    </DefaultDialog>
  );
}
