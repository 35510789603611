import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import {
  ThemeProvider,
  LocaleProvider,
  LocaleName,
  SnackbarProvider,
  UserProvider,
} from '@eas/common-web';

import { Login } from './login';
import { CardPortalApp } from './card-portal/card-portal-app';

export function App() {
  const primary = {
    light: '#d22d40',
    main: '#d22d40',
    dark: '#d22d40',
  };

  const secondary = {
    light: '#003657',
    main: '#003657',
    dark: '#003657',
  };

  const editing = '#FEFFB2';
  const highlight = '#fefeb28a';

  return (
    <ThemeProvider
      primary={primary}
      secondary={secondary}
      editing={editing}
      highlight={highlight}
      radius={2}
      fontFamily={['silkaregular', 'sans-serif']}
    >
      <SnackbarProvider timeout={3000}>
        <LocaleProvider defaultLocale={LocaleName.cs}>
          <UserProvider
            meUrl="/api/prukazy/public/me"
            logoutUrl="/api/prukazy/public/logout"
          >
            <BrowserRouter>
              <Switch>
                <Route exact path="/">
                  <Login />
                </Route>
                <Route path="/portal">
                  <CardPortalApp />
                </Route>
                <Route path="/secure">
                  <Redirect to="/portal/prukazy" />
                </Route>
              </Switch>
            </BrowserRouter>
          </UserProvider>
        </LocaleProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
