import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import { SvgIconProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { v4 } from 'uuid';

import { NavItemType } from './types';

const useStyles = makeStyles((theme) => ({
  itemText: {
    color: '#ffffff',
    '& span': {
      fontWeight: 700,
    },
  },
  icon: {
    color: '#ffffff',
    minWidth: '50px',
  },
  subMenu: {
    padding: '0 0 0 8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  subItem: {
    textDecorationColor: 'transparent',
    transition: '0.3s',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: '#ffffff',
    },
  },
  activeSubItem: {
    backgroundColor: '#fff',
    textDecorationColor: 'transparent',
    transition: '0.3s',
    '& span': {
      color: theme.palette.secondary.main,
    },
    '& svg': {
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      backgroundColor: '#fff',
      textDecoration: 'underline',
      textDecorationColor: theme.palette.secondary.main,
    },
  },
}));

interface NavItemProps {
  linkTo: string;
  exact?: boolean;
  className?: string;
  activeClassName?: string;
  Icon: (props: SvgIconProps) => JSX.Element;
  label: string;
  subItems?: NavItemType[];
}

export function NavItem({
  label,
  linkTo,
  Icon,
  exact = false,
  className,
  activeClassName,
  subItems = [],
}: NavItemProps) {
  const classes = useStyles();
  const { pathname } = useLocation();

  const [open, setOpen] = useState(
    !!matchPath(pathname, { path: '/portal/prukazy' })
  );

  const hasSubItems = subItems.length > 0;
  const handleClick = () => setOpen(!open);

  useEffect(() => {
    if (matchPath(pathname, { path: '/portal/prukazy' }) === null) {
      setOpen(false);
    }
  }, [pathname]);

  return (
    <>
      <ListItem
        button
        component={NavLink}
        to={linkTo}
        className={className}
        exact={exact}
        activeClassName={activeClassName}
        onClick={handleClick}
      >
        <ListItemIcon className={classes.icon}>
          <Icon />
        </ListItemIcon>
        <ListItemText className={classes.itemText}>{label}</ListItemText>
        {hasSubItems && (open ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </ListItem>
      {hasSubItems && (
        <Collapse in={open} unmountOnExit timeout="auto">
          <List component="nav" className={classes.subMenu}>
            {subItems.map((item) => (
              <NavItem
                key={v4()}
                {...item}
                className={classes.subItem}
                activeClassName={classes.activeSubItem}
                exact
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
