import * as yup from 'yup';

import { NewCardValues } from './new-card-types';

const GDPR_MESSAGE = 'Musíte souhlasit se zpracováním osobních údajů';

export const newCardValidationSchema = yup.object<NewCardValues>().shape({
  photo: yup.mixed<File>().required('Musíte nahrát fotografii'),
  cardId: yup.string().required('Musíte určit typ průkazu'),
  pickupWorkplace: yup.string().required('Musíte určit místo vyzvednutí'),
  gdpr: yup.boolean().required(GDPR_MESSAGE).oneOf([true], GDPR_MESSAGE),
  email: yup.string().email('Email není validní').required('Email je povinný'),
});
