import React from 'react';
import { get } from 'lodash';
import MuiTableCell from '@material-ui/core/TableCell';

import { TableCellProps } from './table-types';

function defaultValueSelector({ row, column }: TableCellProps) {
  return get(row, column.id);
}

export function TableCell(props: TableCellProps) {
  const { valueSelector = defaultValueSelector } = props.column;
  const value = valueSelector(props);

  const CellComponent = props.column.CellComponent;

  return (
    <MuiTableCell width={props.column.width ?? 400} align={props.column.align}>
      {CellComponent ? (
        <CellComponent data={value} allData={props.row} />
      ) : (
        value
      )}
    </MuiTableCell>
  );
}
