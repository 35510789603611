import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { SidePanelNav } from './side-panel-nav';
import logo from '../../assets/logo-uk-white-cz.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '1rem',
  },
  header: {
    padding: '1rem 0',
  },
  logo: {
    height: 'auto',
    width: 200,
    [theme.breakpoints.down('md')]: {
      width: 125,
    },
  },
}));

export function SidePanel() {
  const classes = useStyles();

  return (
    <aside className={classes.root}>
      <Grid
        container
        alignItems="center"
        justify="center"
        spacing={1}
        className={classes.header}
      >
        <Grid item>
          <img
            src={logo}
            alt="Charles University logo"
            className={classes.logo}
          />
        </Grid>
      </Grid>
      <SidePanelNav />
    </aside>
  );
}
